<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
        class="space-y-4"
        @submit.prevent="submitPrevent()"
        @keydown="removeError($event.target.id)">

        <h3 class="my-4 text-2xl">Crear hora de clase</h3>

        <div class="w-full">
            <label for="start_hour" class="block mb-2 w-full">
                Hora de entrada
                <span class="text-red-600 font-bold">*</span>
            </label>
            <div class="flex">
                <select id="start_hour" v-model="classhour.start_hour" class="rounded-md w-full border border-gray-200 p-2">
                    <option value="">-- Seleccione --</option>
                    <option :value="n" v-for="n in Array.from({ length: 12 }, (_, e) => e + 1)" :key="n">
                        <!--                <option :value="n" v-for="n in Array.from({ length: 16 }, (_, e) => e + 7)" :key="n">-->
                        {{ n }}
                    </option>
                </select>
                <select id="start_hour_meridian" v-model="meridians.start_hour" class="rounded-md w-full border border-gray-200 p-2 ml-2">
                    <option value="am" selected>a.m.</option>
                    <option value="pm">p.m.</option>
                </select>
            </div>
            <span v-if="error('start_hour')" class="text-red-600 block">{{ error('start_hour') }}</span>
      </div>
        <div class="w-full">
          <label for="start_minute" class="block mb-2 w-full">
            Minuto de entrada
            <span class="text-red-600 font-bold">*</span>
          </label>
          <select id="start_minute" v-model="classhour.start_minute" class="rounded-md w-full border border-gray-200 p-2">
            <option value="">-- Seleccione --</option>
            <option :value="n" v-for="n in Array.from({ length: 60 }, (_, e) => `${e <= 9 ? '0' : ''}${e}`)" :key="n">
              {{ n }}
            </option>
          </select>
          <span v-if="error('start_minute')" class="text-red-600 block">{{ error('start_minute') }}</span>
        </div>

        <div class="w-full">
              <label for="end_hour" class="block mb-2 w-full">
                Hora de salida
                <span class="text-red-600 font-bold">*</span>
              </label>
            <div class="flex">
                <select id="end_hour" v-model="classhour.end_hour" class="rounded-md w-full border border-gray-200 p-2">
                    <option value="">-- Seleccione --</option>
                    <option :value="n" v-for="n in Array.from({ length: 12 }, (_, e) => e + 1)" :key="n">
                        {{ n }}
                    </option>
                </select>
                <select id="end_hour_meridian" v-model="meridians.end_hour" class="rounded-md w-full border border-gray-200 p-2 ml-2">
                    <option value="am" selected>a.m.</option>
                    <option value="pm">p.m.</option>
                </select>
            </div>

              <span v-if="error('end_hour')" class="text-red-600 block">{{ error('end_hour') }}</span>
        </div>
        <div class="w-full">
            <label for="end_minute" class="block mb-2 w-full">
                Minuto de salida
                <span class="text-red-600 font-bold">*</span>
            </label>
            <div class="flex">
                <select id="end_minute" v-model="classhour.end_minute" class="rounded-md w-full border border-gray-200 p-2">
                    <option value="">-- Seleccione --</option>
                    <option :value="n" v-for="n in Array.from({ length: 60 }, (_, e) => `${e <= 9 ? '0' : ''}${e}`)" :key="n">
                        {{ n }}
                    </option>
                </select>
            </div>
          <span v-if="error('end_minute')" class="text-red-600 block">{{ error('end_minute') }}</span>
        </div>

          <label for="end_minute" class="block mb-2 w-full mt-4">
            Período
            <span class="text-red-600 font-bold">*</span>
          </label>

          <v-select
            @input="removeError('period_id')"
            :loading="loadingPeriods"
            :options="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))"
            :reduce="$0 => $0.value"
            v-model="classhour.period_id"/>

          <span v-if="error('period_id')" class="text-red-600 block">{{ error('period_id') }}</span>

        <div class="flex space-x-2">
          <a-button mode="primary" type="submit" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'classhours' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    classhour: {
      start_hour: null,
      start_minute: null,
      end_hour: null,
      end_minute: null,
      period_id: null
    },
    meridians: {
      start_hour: 'am',
      end_hour: 'am'
    },
    isUpdating: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      isTeacher: 'session/isTeacher',
      isAdmin: 'session/isAdmin',
    }),
    ...mapState({
      classhours: state => state.classhours.pagination.data,
      currentClasshourOnState: state => state.classhours.current,
      loading: state => state.classhours.loading,
      periods: state => state.periods.all,
      loadingPeriods: state => state.periods.loading
    }),
  },
  methods: {
    ...mapActions({
      fetchClasshour: 'classhours/show',
      store: 'classhours/store',
      update: 'classhours/update',
      removeError: 'removeError',
      fetchPeriods: 'periods/index'
    }),
      submitPrevent () {
          let hoursTransform = { ...this.classhour }
          hoursTransform.start_hour = (this.meridians.start_hour === 'pm') && (hoursTransform.start_hour < 12)
              ? hoursTransform.start_hour + 12
              : hoursTransform.start_hour

          hoursTransform.end_hour = (this.meridians.end_hour === 'pm') && (hoursTransform.end_hour < 12)
              ? hoursTransform.end_hour + 12
              : hoursTransform.end_hour

          if (hoursTransform.start_hour === 12 && this.meridians.start_hour === 'am') {
              hoursTransform.start_hour = 0
          }

          if (hoursTransform.end_hour === 12 && this.meridians.end_hour === 'am') {
              hoursTransform.end_hour = 0
          }

          if (this.isUpdating) {
              this.update(hoursTransform).then(() => this.$router.push({ name: 'classhours' }))
          } else {
              this.store(hoursTransform).then(() => this.$router.push({ name: 'classhours' }))
          }
      }
  },
  watch: {
    currentClasshourOnState(val) {
      this.classhour = {...val}
    }
  },
  created() {
    const id = this.$route.query.update
    this.fetchPeriods({ limit: 0, sort_by: 'year,asc' })

    if (id) {
      this.isUpdating = true

      const found = this.classhours.find(it => it.id === parseInt(id))

      if (found) {
        this.classhour = {...found}
      } else {
        this.fetchClasshour(id)
      }
    }

    if (this.$route.query.period) {
      let period = parseInt(this.$route.query.period)
      if (period) {
        this.classhour.period_id = period
      }
    }
  }
}
</script>
